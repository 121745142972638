<template>
  <div class='m-1 mb-5 mt-3' style='width: 99%'>
    <b-modal
      header-bg-variant='info'
      header-text-variant='light'
      ref='selectUser'
      size='lg'
      scrollable
      hide-footer
      title='Adicionar colaborador'
    >

      <b-modal
        header-bg-variant='info'
        header-text-variant='light'
        ref='enviarConvite'
        id='enviarConvite'
        size='lg'
        scrollable
        hide-footer
        title='Enviar convite'
      >
        <div>
          <p>
            O colaborador <strong>{{ userSelect }}</strong> ainda não esta cadastrado na plataforma.
          </p>
          <p>
            Deseja enviar um convite para o colaborador <strong>{{ userSelect }}</strong> se cadastrar na
            platafora?<br />
          </p>
          <p>
            Essa ação vai reservar o acesso para o colaborador <strong>{{ userSelect }}</strong>.
          </p>
        </div>

        <div class='d-flex'>
          <base-button
            danger
            @click='$refs.enviarConvite.hide()'
            style='height: 48px'
            class='w-50 mr-1'
          >
            <strong>Cancelar</strong>
          </base-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50 '
          >
            <base-button
              sucess
              @click='sendInvitation()'
              style='height: 48px'
              class='w-100'
            >
              <strong>Enviar convite</strong>
            </base-button>
          </b-overlay>
        </div>
      </b-modal>
      <b-alert show variant='danger' :class='{ hidden: !hasErrors() }'>
        {{ error }}<br />
      </b-alert>
      <div class='d-flex w-100'>
        <div class='d-flex w-100 align-items-end mr-3'>
          <base-input-text
            class='w-75 mr-1'
            block
            v-model='userSelect'
            label='E-mail do colaborador'
            placeholder='Digite o e-mail do colaborador'
          />

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class=' w-25'
          >
            <base-button
              block
              @click='searchUser()'
              style='height: 48px'
              class='w-100'
            >
              Adicionar
            </base-button>
          </b-overlay>
        </div>
      </div>
    </b-modal>

    <b-row class='d-flex justify-content-between'>
      <b-col align='start'>
        <span>Acessos comprados: <strong>{{ numberExistingUsers }}</strong></span><br />
        <span>Acessos restante: <strong>{{ remainingAmount }}</strong></span>
      </b-col>

      <b-col align='end' class='d-flex justify-content-end'>
        <base-button
          :disabled='numberExistingUsers >= maximumAmountOfHitsCanBePurchased'
          class='mr-1 btn py-2'
          @click='buyHits()'
        >
          Compra acessos
        </base-button>

        <base-button
          class='btn py-2'
          :disabled='remainingAmount <= 0'
          @click='showModal()'
        >
          Adicionar colaborador
        </base-button>
      </b-col>
    </b-row>

    <b-card no-body class='mt-3'>
      <b-tabs pills card>
        <b-tab @click="setBreadcrumbTabsName('Meus colaboradores')" no-body title='Meus colaboradores'>
          <div class='w-100 m-2'>
            <collaborator-table
              :collaborators='getCollaborators'
              :collaboratorsMeta='getCollaboratorsMeta'
              @change-collaborators='changeCollaborators'
              @reload-data='init'
            />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Histórico')" no-body title='Histórico'>
          <div class='w-100 m-2'>
            <collaborator-historic-table
              :collaboratorsHistoric='getCollaboratorsHistoric'
              :collaboratorsHistoricMeta='getCollaboratorsHistoricMeta'
              @change-collaborators-historic='changeCollaboratorsHistoric'
            />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Acessos comprados')" no-body title='Acessos comprados'>
          <div class='w-100 m-2'>
            <acess-collaborator-table
              :accessesCollaborator='getAccessesCollaborator'
              :accessCollaboratorMeta='getAccessCollaboratorMeta'
              @change-collaborators='accessChangeCollaborators'
              @reload-data='init'
            />
          </div>
        </b-tab>

        <b-tab @click="setBreadcrumbTabsName('Convites')" no-body title='Convites'>
          <div class='w-100 m-2'>
            <invitations-collaborator-table
              :invitationsCollaborator='getInvitationsCollaborator'
              :invitationsCollaboratorMeta='getInvitationsCollaboratorMeta'
              @change-collaborators='changeInvitationsCollaborator'
              @reload-data='init'
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CollaboratorTable from '@/components/Collaborator/CollaboratorTable'
import AcessCollaboratorTable from '@/components/Collaborator/AcessCollaboratorTable'
import CollaboratorHistoricTable from '@/components/Collaborator/CollaboratorHistoricTable'
import InvitationsCollaboratorTable from '@/components/Collaborator/InvitationsCollaboratorTable'

export default {
  name: 'Collaborators',
  components: {
    CollaboratorTable,
    AcessCollaboratorTable,
    CollaboratorHistoricTable,
    InvitationsCollaboratorTable
  },
  created() {
    this.setBreadcrumbTabsName('Meus colaboradores')
  },
  data() {
    return {
      loading: false,
      userSelect: '',
      numberExistingUsers: 0,
      remainingAmount: 0,
      maximumAmountOfHitsCanBePurchased: 10,
      breadcrumbTabsName: 'Meus colaboradores'
    }
  },
  async mounted() {
    try {
      await this.init()
    } catch (e) {

    }

  },
  computed: {
    ...mapGetters('collaborator', {
      getCollaborators: 'getCollaborators',
      getCollaboratorsMeta: 'getCollaboratorsMeta',
      getCollaboratorsHistoric: 'getCollaboratorsHistoric',
      getCollaboratorsHistoricMeta: 'getCollaboratorsHistoricMeta'
    }),
    ...mapGetters('acessCollaborator', {
      getAccessCollaboratorMeta: 'getAccessCollaboratorMeta',
      getAccessesCollaborator: 'getAccessesCollaborator'
    }),
    ...mapGetters('invitationCollaborator', {
      getInvitationsCollaborator: 'getInvitationsCollaborator',
      getInvitationsCollaboratorMeta: 'getInvitationsCollaboratorMeta'
    }),
    user() {
      return this.$store.getters['user/current']
    },
    error() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    ...mapActions('user', {
      handleUserByUsername: 'handleUserByUsername'
    }),
    ...mapActions('userPlans', {
      handlePlanPaidOrPendingByEmail: 'handlePlanPaidOrPendingByEmail'
    }),
    ...mapActions('collaborator', {
      handleCreateCollaborator: 'handleCreateCollaborator',
      handleSetCollaborator: 'handleSetCollaborator',
      handleCollaborators: 'handleCollaborators',
      handleCollaboratorsHistoric: 'handleCollaboratorsHistoric',
      handleCollaboratorByUserName: 'handleCollaboratorByUserName'
    }),
    ...mapActions('acessCollaborator', {
      handleAccessCollaborator: 'handleAccessCollaborator',
      handleAmountAccessCollaboratorRemaining: 'handleAmountAccessCollaboratorRemaining',
      handleAmountAccessCollaboratorPaid: 'handleAmountAccessCollaboratorPaid'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('invitationCollaborator', {
      handleSendInvitation: 'handleSendInvitation',
      handleInvitationsCollaborator: 'handleInvitationsCollaborator'
    }),
    setBreadcrumbTabsName(breadcrumbTabsName) {
      this.breadcrumbTabsName = breadcrumbTabsName
      const breadcrumb = {
        page: 'Colaboradoers',
        subPage: {
          name: 'Colaboradores',
          active: false
        },
        tabs: [
          {
            name: this.breadcrumbTabsName,
            active: true
          }
        ]
      }
      this.setBreadcrumb(breadcrumb)
    },

    async init() {
      const { remainingAmount } = await this.handleAmountAccessCollaboratorRemaining(this.user.plan._id)
      const { numberExistingUsers } = await this.handleAmountAccessCollaboratorPaid(this.user.plan._id)
      this.numberExistingUsers = numberExistingUsers
      this.remainingAmount = remainingAmount
      await this.changeCollaborators({ userMaster: this.user.id })
      await this.changeCollaboratorsHistoric({ userMaster: this.user.id })
      await this.handleAccessCollaborator({ planId: this.user.plan._id })
      await this.changeInvitationsCollaborator({ userMaster: this.user.id })
    },

    async buyHits() {
      await this.$router.push({ name: 'BuySingleAccesses', params: { planId: this.user.plan._id } })
    },

    async changeCollaborators(payload) {
      const params = {
        ...this.getCollaboratorsMeta,
        ...payload,
        userMaster: this.user.id
      }

      await this.handleCollaborators(params)
    },

    async accessChangeCollaborators(payload) {
      const params = {
        ...this.getAccessCollaboratorMeta,
        ...payload,
        planId: this.user.plan._id
      }

      await this.handleAccessCollaborator(params)
    },

    async changeCollaboratorsHistoric(payload) {
      const params = {
        ...this.getCollaboratorsHistoricMeta,
        ...payload,
        userMaster: this.user.id
      }

      await this.handleCollaboratorsHistoric(params)
    },

    async changeInvitationsCollaborator(payload) {
      const params = {
        ...this.getInvitationsCollaboratorMeta,
        ...payload,
        userMaster: this.user.id
      }

      await this.handleInvitationsCollaborator(params)
    },

    async sendInvitation() {
      try {
        this.loading = true
        const body = {
          collaboratorEmail: this.userSelect.trim(),
          planId: this.user.plan._id,
          userMasterId: this.user.id
        }

        const { data, status, errors } = await this.handleSendInvitation(body)

        this.loading = false
        this.$refs.enviarConvite.hide()
        this.$refs.selectUser.hide()
        let message = errors
        let variant = 'danger'

        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.init()
      } catch (erro) {
        this.loading = false
        this.$refs.enviarConvite.hide()
        this.$refs.selectUser.hide()
        this.$bvToast.toast('Verefique os dados e tente novamente', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      }
    },
    async searchUser() {
      try {
        this.loading = true
        await this.$store.dispatch('access/updateError', '')
        const { collaborator: collaboratorExist } = await this.handleCollaboratorByUserName({
          userName: this.userSelect,
          userMaster: this.user.id
        })

        if (collaboratorExist) {
          this.loading = false
          await this.$store.dispatch('access/updateError', `O colaborador ${this.userSelect}, já tem um plano ativo liberado.`)
          return
        }

        const { userPlan: userPlanExist } = await this.handlePlanPaidOrPendingByEmail(this.userSelect)

        if (userPlanExist) {
          this.loading = false
          await this.$store.dispatch('access/updateError', `O colaborador ${this.userSelect}, tem um plano pago ou com pagamento pendente.`)
          return
        }

        this.loading = false
        const { user } = await this.handleUserByUsername(this.userSelect)
        if (!user) {
          this.$refs.enviarConvite.show()
          this.loading = false
        }
        const collaborator = {
          userMaster: this.user.id,
          collaborator: user._id,
          planId: this.user.plan._id
        }
        this.loading = false

        this.handleSetCollaborator(collaborator)
        const { data, status, errors } = await this.handleCreateCollaborator()
        this.$refs.selectUser.hide()
        let message = errors
        let variant = 'danger'

        if (status === 200 || status === 201) {
          message = 'Acesso liberado com sucesso!'
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        await this.init()
      } catch (e) {

      }
    },
    async showModal() {
      this.$refs.selectUser.show()
      await this.$store.dispatch('access/updateError', '')
      this.userSelect = ''
    },
    hasErrors() {
      return !!this.error
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.btn {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>
