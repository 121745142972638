<template>
  <div class='surveys-table'>
    <base-table
      v-if='collaboratorsHistoricMeta && collaboratorsHistoricMeta'
      :columns='columns'
      :data='collaboratorsHistoric'
      :total-items='collaboratorsHistoricMeta.total'
      :start-items='collaboratorsHistoricMeta.skip + 1'
      :max-items='collaboratorsHistoricMeta.limit'
      @set-page='handleChangeCollaborators'
    >
      <template v-slot:body-description='row'>
        {{ row.item.description ? descriptionHistory(row.item) : '-' }}
      </template>

      <template v-slot:body-user='row'>
        {{ row.item.user.username ? row.item.user.username : '' }}
      </template>
    </base-table>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'CollaboratorHistoricTable',
  props: {
    collaboratorsHistoric: { type: Array, require: true },
    collaboratorsHistoricMeta: { type: Object, require: true }
  },
  components: {},
  mounted() {
  },
  async created() {

  },
  data() {
    return {
      columns: [
        { key: 'username', value: 'Colaborador' },
        { key: 'collaboratorRegistrationDate', value: 'Data de cadastro do colaborador' },
        { key: 'createdAt', value: 'Data do histórico' },
        { key: 'description', value: 'Histórico' }
      ]
    }
  },
  computed: {
    ...mapGetters('user', {
      user: 'current',
    }),
  },
  methods: {
    descriptionHistory(collaborator){
      if(collaborator.plan && collaborator.plan.dueDate && moment(new Date(collaborator.plan.dueDate)).isBefore(moment(new Date(this.user.currentDate)))){
        return 'O plano foi cancelao, a data de vencimento plano foi atinginda.'
      } else if(collaborator.description) {
        return collaborator.description
      } else {
        return '-'
      }
    },
    handleChangeCollaborators(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.collaboratorsHistoricMeta.limit)
      }
      this.$emit('change-collaborators-historic', params)

    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background: var(--blue);
  color: white;
}
</style>
