<template>
  <div class='surveys-table'>
    <b-modal
      header-bg-variant='info'
      header-text-variant='light'
      ref='cancelAccess'
      size='lg'
      scrollable
      hide-footer
      title='Cancelar compra de acessos'
    >
      <div class='d-flex w-100'>
        <div class='w-100 align-items-end mr-3' v-if='access'>
          <h5>Informações da compra</h5>

          <p>
            <strong>Data da compra: </strong> <span>{{ formatDate(access.createdAt) }}</span> <br />
            <strong>Data do pagamento: </strong> <span>{{ formatDate(access.paymentDate) }}</span> <br />
            <strong>Quantidade de acessos comprados: </strong> <span>{{ access.amountPurchased }}</span> <br />
            <strong>Quantidade de acessos restante: </strong> <span>{{ access.remainingAmount }}</span> <br />
            <strong>Forma de pagamento: </strong>
            <span>{{ access.method === 'credit_card' ? 'Cartão de crédito' : 'Boleto bancário' }}</span> <br />
          </p>

          <p>O prazo para reembolso é de até {{ refundDaysLimit }} dias após a data da compra.</p>

          <p v-if='verifyAccessEntitledToRefund(access.createdAt)'>
            <span>
              A compra está dentro do prazo para reembolso, você sera reembolsado, caso tenha concedido acesso para algum colaborador, o mesmo sera revogado.
            </span>
            <br /> <br />

            <span v-if="access.method === 'bank_slip'">
              Devido à forma de pagamento ser por boleto bancário, o suporte entrará em conta para fazer o seu reembolso.
            </span>
          </p>

          <p v-else>
            A compra não está dentro do prazo para reembolso.
          </p>

          <h5>Deseja relamente cancelar essa compra?</h5>

          <div class='d-flex mt-3'>
            <base-button
              danger
              @click='$refs.cancelAccess.hide()'
              style='height: 48px'
              class='w-50 mr-1'
            >
              Manter acesso
            </base-button>

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class='w-50 '
            >
              <base-button
                sucess
                @click='cancel()'
                style='height: 48px'
                class='w-100 btn'
              >
                <span>Sim, cancelar</span>
              </base-button>
            </b-overlay>
          </div>
        </div>
      </div>
    </b-modal>

    <base-table
      v-if='accessesCollaborator && accessCollaboratorMeta'
      :columns='columns'
      :data='accessesCollaborator'
      :total-items='accessCollaboratorMeta.total'
      :start-items='accessCollaboratorMeta.skip + 1'
      :max-items='accessCollaboratorMeta.limit'
      @set-page='handleChangeCollaborators'
    >
      <template v-slot:body-status='row'>
        {{ row.item.status ? returnStatusDescription(row.item.status) : '-' }}
      </template>
      <template v-slot:body-method='row'>
        {{ row.item.method ? returnPaymentMethodDescription(row.item.method) : '-' }}
      </template>
      <template v-slot:body-invoiceUrl='row'>
        <p v-if='row.item.invoiceUrl'>
          <a :href='row.item.invoiceUrl' target='_blank'>Acessar</a>
        </p>
      </template>

      <template v-slot:body-createdAt='row'>
        {{ row.item.createdAt ? formatDate(row.item.createdAt) : '-' }}
      </template>

      <template v-slot:body-paymentDate='row'>
        {{ row.item.paymentDate ? formatDate(row.item.paymentDate) : '-' }}
      </template>

      <template v-slot:body-remainingAmount='row'>
        {{ row.item.remainingAmount }}
      </template>
      <template v-slot:body-actions='row'>
        <div v-if="row.item.plan && row.item.plan.length <= 0 && row.item.status === 'paid'">
          <b-popover target='cancelar_acesso' triggers='hover' placement='top'>
            <template #title>Cancelar acesso comprado</template>
          </b-popover>
          <i
            v-if='row.item.plan'
            id='cancelar_acesso'
            class='fas fa-retweet fa-times p-1'
            @click='showModalCancelAccess(row.item)'
          />
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import moment from 'moment'
import format from 'date-fns/esm/format/index'
import { mapActions } from 'vuex'

export default {
  name: 'AcessCollaboratorTable',
  props: {
    accessesCollaborator: { type: Array, require: true },
    accessCollaboratorMeta: { type: Object, require: true }
  },
  async created() {
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  data() {
    return {
      access: null,
      loading: false,
      refundDaysLimit: 7,
      columns: [
        { key: 'status', value: 'Status' },
        { key: 'method', value: 'Meio de pagamento' },
        { key: 'createdAt', value: 'Data da compra' },
        { key: 'paymentDate', value: 'Data do pagamento' },
        { key: 'amountPurchased', value: 'Quantidade Comprada' },
        { key: 'remainingAmount', value: 'Quantidade restante' },
        { key: 'invoiceUrl', value: 'Segunda via do boleto / fatura' },
        { key: 'actions', value: 'Ações' }
      ]
    }
  },
  components: {},
  methods: {
    ...mapActions('acessCollaborator', {
      handleCancelAccess: 'handleCancelAccess'
    }),
    showModalCancelAccess(item) {
      this.access = item
      this.$refs.cancelAccess.show()
    },
    returnStatusDescription(status) {
      switch (status) {
        case 'paid':
          return 'Pago'
        case 'canceled':
          return 'Cancelado'
        case 'refunded':
          return 'Reembolsado'
        default:
          return 'Pendente'
      }
    },

    returnPaymentMethodDescription(method) {
      switch (method) {
        case 'credit_card':
          return 'Cartão de crédito'
        case 'manual_release':
          return 'Liberado manualmente'
        default:
          return 'Boleto bancário'
      }
    },

    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },

    handleChangeCollaborators(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.collaboratorsMeta.limit)
      }
      this.$emit('change-collaborators', params)
    },

    verifyAccessEntitledToRefund(createdAt) {
      const useDays = moment.duration(moment(this.user.currentDate).diff(moment(createdAt))).asDays()
      return useDays <= this.refundDaysLimit
    },
    async cancel() {
      try {
        this.loading = true
        const { data, status, errors } = await this.handleCancelAccess(this.access._id)
        console.log(data)
        this.$refs.cancelAccess.hide()
        this.loading = false
        let message = errors
        let variant = 'danger'

        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.$emit('reload-data')
      } catch (e) {

      }
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background: var(--blue);
  color: white;
}

.btn {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>
