<template>
  <div class='surveys-table'>
    <b-modal
      header-bg-variant='danger'
      header-text-variant='light'
      hide-footer size='lg'
      id='revogar'
      title='Revogar o acesso do colaborador'
      ref='revogar'
    >
      <p style='font-size: 19px'>
        Esta ação vai revogar o acesso do colaborador <strong> {{ userRevokeAccess.username }}</strong>.<br />
        Deseja realmente revogar o acesso do usuário?
      </p>
      <div class='d-flex mb-3'>
        <base-button
          danger
          @click="$refs['revogar'].hide()"
          style='height: 48px'
          class='w-50 mr-2'
        >
          Cancelar
        </base-button>

        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='w-50 '
        >
          <base-button
            sucess
            @click='revokeAccess()'
            style='height: 48px'
            class='w-100'
          >
            Revogar acesso
          </base-button>
        </b-overlay>
      </div>
    </b-modal>

    <b-modal
      header-bg-variant='info'
      header-text-variant='light'
      ref='transferPlan'
      size='lg'
      scrollable
      hide-footer
      title='Transferência de plano entre usuários'
    >
      <b-alert show variant='danger' :class='{ hidden: !hasErrors() }'>
        {{ error }}<br />
      </b-alert>

      <b-modal hide-footer size='lg' id='confirmation' title='Confirmar' ref='confirmation'>
        <p> {{ description }}</p>
        <div class='d-flex'>
          <base-button
            danger
            @click="$refs['confirmation'].hide()"
            style='height: 48px'
            class='w-50 mr-1'
          >
            Cancelar
          </base-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50 '
          >
            <base-button
              sucess
              @click='transferPlan()'
              style='height: 48px'
              class='w-100'
            >
              Sim
            </base-button>
          </b-overlay>
        </div>
      </b-modal>

      <div class='d-flex w-100'>
        <div class='w-50 mr-2'>
          <div class='w-100 '>
            <base-input-text
              class='w-100 mr-1'
              block
              v-model='userSelect'
              label='E-mail do usuário'
              placeholder='Digite o e-mail do usuário'
            />

            <b-overlay
              :show='loading'
              rounded
              opacity='0.6'
              spinner-small
              spinner-variant='primary'
              class=' w-100 mt-2'
            >
              <base-button
                block
                @click='searchUser()'
                :disabled='!userSelect'
                style='height: 48px'
                class='w-100'
              >
                Transferir plano
              </base-button>
            </b-overlay>
          </div>
        </div>

        <div class='w-50' v-if='planToBeTransferred'>
          Usuário do plano: <strong>{{ planToBeTransferred.username }}</strong><br />
          Plano: <strong>Anual</strong> <br />
          Data de assinatura:
          <strong>{{ planToBeTransferred.plan.createdAt | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm')
            }}</strong><br />

          Validade do plano:
          <strong>{{ planToBeTransferred.plan.dueDate | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm')
            }}</strong><br />
          Dias de utilização: <strong>{{ new Date() | moment('diff', planToBeTransferred.plan.createdAt, 'days')
          }}</strong> <br />
        </div>
      </div>
    </b-modal>

    <base-table
      v-if='collaborators && collaboratorsMeta'
      :columns='columns'
      :data='collaborators'
      :total-items='collaboratorsMeta.total'
      :start-items='collaboratorsMeta.skip + 1'
      :max-items='collaboratorsMeta.limit'
      @set-page='handleChangeCollaborators'
    >
      <template v-slot:body-dataVencimentoPlano='row'>
        {{ row.item.dataVencimentoPlano ? row.item.dataVencimentoPlano : '-' }}
      </template>

      <template v-slot:body-user='row'>
        {{ row.item.user.username ? row.item.user.username : '' }}
      </template>
      <template v-slot:body-actions='row'>
        <div>
          <b-popover target='popover-target-1' triggers='hover' placement='top'>
            <template #title>Transferir plano</template>
          </b-popover>
          <i
            v-if='row.item.plan'
            id='popover-target-1'
            class='fas fa-retweet icon-transfer p-1'
            style='font-size: 20px;'
            @click='showModalTransferPlan(row.item)'
          />
        </div>

        <div>
          <b-popover target='fa-times' triggers='hover' placement='top'>
            <template #title>Revogar acesso</template>
          </b-popover>
          <i
            v-if='row.item.plan'
            id='fa-times'
            class='fas fa-retweet fa-times p-1'
            @click='showModalRevokeAccess(row.item)'
          />
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CollaboratorTable',
  props: {
    collaborators: { type: Array, require: true },
    collaboratorsMeta: { type: Object, require: true }
  },
  components: {},
  async created() {

  },
  data() {
    return {
      description: '',
      userRevokeAccess: {},
      planToBeTransferred: null,
      userReceiveThePlan: null,
      userSelect: 'denisinhakata@hotmail.com',
      openDialog: false,
      loading: false,
      columns: [
        { key: 'username', value: 'Colaborador' },
        { key: 'dataCadastro', value: 'Data de cadastro do colaborador' },
        { key: 'dataVencimentoPlano', value: 'Data de vencimento do plano' },
        { key: 'actions', value: 'Ações' }
      ]
    }
  },
  computed: {
    error() {
      return this.$store.getters['access/error']
    }
  },
  methods: {
    ...mapActions('user', {
      handleUserByUsername: 'handleUserByUsername'
    }),
    ...mapActions('collaborator', {
      handleCollaboratorTransferPlan: 'handleCollaboratorTransferPlan',
      handleCollaboratorRevokeAccess: 'handleCollaboratorRevokeAccess'
    }),

    async showModalRevokeAccess(plan) {
      this.userRevokeAccess = plan
      this.$refs['revogar'].show()
    },

    async showModalTransferPlan(plan) {
      this.planToBeTransferred = plan
      this.$refs['transferPlan'].show()
      this.userSelect = ''
      await this.$store.dispatch('access/updateError', '')
    },

    async transferPlan() {
      try {
        this.loading = true
        const body = {
          collaboratorId: this.planToBeTransferred._id,
          userId: this.userReceiveThePlan._id
        }
        const { data, status, errors } = await this.handleCollaboratorTransferPlan(body)

        this.loading = false
        this.$refs['transferPlan'].hide()
        this.$refs['confirmation'].hide()
        this.$emit('reload-data')
        let message = errors
        let variant = 'danger'

        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      } catch (e) {
      }
    },

    async revokeAccess() {
      try {
        this.loading = true
        const body = {
          collaboratorId: this.userRevokeAccess._id
        }
        const { data, status, errors } = await this.handleCollaboratorRevokeAccess(body)

        this.loading = false
        this.$refs['revogar'].hide()
        this.$emit('reload-data')
        let message = errors
        let variant = 'danger'

        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
      } catch (e) {
      }
    },

    async searchUser() {
      try {
        this.loading = true
        await this.$store.dispatch('access/updateError', '')
        if (this.userSelect.trim() === this.planToBeTransferred.username.trim()) {
          await this.$store.dispatch('access/updateError', 'O usuário já esta vinculado ao plano!')
          this.loading = false
          return
        }
        const { user } = await this.handleUserByUsername(this.userSelect.trim())
        if (!user) {
          this.loading = false
          await this.$store.dispatch('access/updateError', 'Usuário não encontrado, verifique o email e tente novamente')
          return
        }
        this.userReceiveThePlan = user
        this.description = `Deseja realmente transferir o plano do usuário ${this.planToBeTransferred.username} para o ${this.userReceiveThePlan.username}`
        this.loading = false
        this.$refs['confirmation'].show()
      } catch (e) {
      }
    },

    hasErrors() {
      return !!this.error
    },

    handleChangeCollaborators(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.collaboratorsMeta.limit)
      }
      this.$emit('change-collaborators', params)

    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background: var(--blue);
  color: white;
}
</style>
