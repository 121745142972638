<template>
  <div class='surveys-table'>
    <b-modal
      header-bg-variant='danger'
      header-text-variant='light'
      hide-footer size='lg'
      id='revogar'
      title='Revogar o convite do colaborador'
      ref='revogar'
    >
      <p style='font-size: 19px'>
        Esta ação vai revogar o convite do colaborador <strong> {{ userRevokeAccess.collaboratorEmail }}</strong>.<br />
        Deseja realmente revogar o convite do usuário?
      </p>
      <div class='d-flex mb-3'>
        <base-button
          danger
          @click="$refs['revogar'].hide()"
          style='height: 48px'
          class='w-50 mr-2'
        >
          Cancelar
        </base-button>

        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='w-50 '
        >
          <base-button
            sucess
            @click='revokeAccess()'
            style='height: 48px'
            class='w-100'
          >
            Revogar convite
          </base-button>
        </b-overlay>
      </div>
    </b-modal>

    <base-table
      v-if='invitationsCollaborator && invitationsCollaboratorMeta'
      :columns='columns'
      :data='invitationsCollaborator'
      :total-items='invitationsCollaboratorMeta.total'
      :start-items='invitationsCollaboratorMeta.skip + 1'
      :max-items='invitationsCollaboratorMeta.limit'
      @set-page='handleChangeCollaborators'
    >
      <template v-slot:body-collaboratorRegistered='row'>
        {{ row.item.collaboratorRegistered ? 'Sim' : 'Não' }}
      </template>

      <template v-slot:body-collaboratorUsedAccessSentByInvitation='row'>
        {{ row.item.collaboratorUsedAccessSentByInvitation ? 'Sim' : 'Não' }}
      </template>

      <template v-slot:body-active='row'>
        {{ row.item.active ? 'Sim' : 'Não' }}
      </template>

      <template v-slot:body-method='row'>
        {{ row.item.method ? returnPaymentMethodDescription(row.item.method) : '-' }}
      </template>

      <template v-slot:body-createdAt='row'>
        {{ row.item.createdAt ? formatDate(row.item.createdAt) : '-' }}
      </template>

      <template v-slot:body-actions='row'>
        <div v-if='row.item.active'>
          <b-popover target='revokeInvitation' triggers='hover' placement='top'>
            <template #title>Revogar convite</template>
          </b-popover>
          <i
            v-if='row.item.plan'
            id='revokeInvitation'
            class='fas fa-retweet fa-times p-1'
            @click='showModalRevokeInvitation(row.item)'
          />
        </div>
        <p v-else>-</p>
      </template>
    </base-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import format from 'date-fns/esm/format/index'

export default {
  name: 'InvitationsCollaboratorTable',
  props: {
    invitationsCollaborator: { type: Array, require: true },
    invitationsCollaboratorMeta: { type: Object, require: true }
  },
  async created() {
  },

  data() {
    return {
      userRevokeAccess: {},
      loading: false,
      columns: [
        { key: 'collaboratorEmail', value: 'E-mail do colaborador' },
        { key: 'createdAt', value: 'Data do envio' },
        { key: 'collaboratorRegistered', value: 'Colaborador se cadastrou' },
        { key: 'collaboratorUsedAccessSentByInvitation', value: 'Colaborador usando o acesso' },
        { key: 'active', value: 'Ativo' },
        { key: 'actions', value: 'Ações' }
      ]
    }
  },
  components: {},
  methods: {
    ...mapActions('invitationCollaborator', {
      handlerRevokeInvitation: 'handlerRevokeInvitation'
    }),
    async revokeAccess() {
      try {
        this.loading = true
        const { data, status, errors } = await this.handlerRevokeInvitation({ invitationId: this.userRevokeAccess._id })
        this.loading = false
        this.$refs['revogar'].hide()
        this.$emit('reload-data')
        let message = errors
        let variant = 'danger'

        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.$emit('reload-data')
      } catch (e) {

      }
    },
    async showModalRevokeInvitation(plan) {
      this.userRevokeAccess = plan
      this.$refs['revogar'].show()
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    handleChangeCollaborators(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.collaboratorsMeta.limit)
      }
      this.$emit('change-collaborators', params)
    }
  },
  watch: {}
}
</script>

<style lang='scss' scoped>
.modal-see-survey {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 1rem;

    .infos, .answers {
      display: flex;
      flex-direction: column;

      h3 {
        font-weight: bold;
      }
    }
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}

.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}

table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table th {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  background: var(--blue);
  color: white;
}
</style>
